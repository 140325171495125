/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import React from "react"
import bugsnag from "@bugsnag/js"
import bugsnagReact from "@bugsnag/plugin-react"

const bugsnagClient = bugsnag("464eba83e18d04ddf0a046281d9e34cc")
bugsnagClient.use(bugsnagReact, React)

const ErrorBoundary = bugsnagClient.getPlugin("react")
const wrapRootElement = ({ element }) => (
  <ErrorBoundary>{element}</ErrorBoundary>
)

export default wrapRootElement
