module.exports = [{
      plugin: require('/mnt/f/Projects/rebase/aisolutions-landing/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://helloaisolutions.com"},
    },{
      plugin: require('/mnt/f/Projects/rebase/aisolutions-landing/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-142524668-1","anonymize":false},
    },{
      plugin: require('/mnt/f/Projects/rebase/aisolutions-landing/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
