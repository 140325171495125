// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/mnt/f/Projects/rebase/aisolutions-landing/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aibots-implementados-js": () => import("/mnt/f/Projects/rebase/aisolutions-landing/src/pages/aibots-implementados.js" /* webpackChunkName: "component---src-pages-aibots-implementados-js" */),
  "component---src-pages-contacto-js": () => import("/mnt/f/Projects/rebase/aisolutions-landing/src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("/mnt/f/Projects/rebase/aisolutions-landing/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-solicitud-de-demo-js": () => import("/mnt/f/Projects/rebase/aisolutions-landing/src/pages/solicitud-de-demo.js" /* webpackChunkName: "component---src-pages-solicitud-de-demo-js" */)
}

